<template>
  <div class="chat-app">
    <!-- <div class="chat-list"> -->
    <!-- <div class="chat-header">Lista de Tickets</div> -->
    <!-- Lista de chats a la izquierda -->
    <!-- <div class="chat-item">Tickets 1</div> -->
    <!-- <div class="chat-item">Tickets 2</div>
      <div class="chat-item">Tickets 3</div>
      <div class="chat-item">Tickets 4</div> -->
    <!-- Agrega más chats aquí -->
    <!-- </div> -->
    <div class="chat">
      <div class="chat-header">Tickets</div>
      <div class="chat-messages">
        <div v-for="(message, index) in messages" :key="index"
          :class="(usuario.id == message.deId ? ' sent-message ' : ' received-message ') + 'mt-2'">

          <div class="text-right mt-1" style="font-style: italic; font-size: 14px">
            <span v-if="usuario.id != message.deId ">{{ message.de.nombres + " " + message.de.apellidos }}</span>
            <span v-else>{{ usuario.nombres + " " + usuario.apellidos }}</span>
            <br><br>
          </div>

          {{ message.mensaje }}
          <div v-if="message.urlAdjunto!=null" class="text-center mb-3 mt-3">
            <a :href="message.urlAdjunto" target="_blank" >
                <img :src="message.urlAdjunto" width="55" alt="" v-if="['jpg', 'peg','png','bmp','gif'].includes(message.urlAdjunto.substr(message.urlAdjunto.length-3))">
                <img v-else src="/img/viewfile.png" width="55" alt="" v-b-tooltip.top title="click para ver / descargar archivo">
            </a>
          </div>
          <div class="text-right mt-1" style="font-style: italic; font-size: 12px">
            {{ $moment.tz(message.fecha, "America/Bogota").format("DD-MM-YYYY HH:mm") }}
          </div>
        </div>
        <span style="color:#102444 !important" ref="chatmessages">_</span>
      </div>
      <div class="message-input-container">
                      <input ref="fileInput" id="file-upload" type="file" accept="image/*" @change="handleFileUpload" hidden />
                      <!-- <img
                        :src="usuario.imagenPerfilUrl"
                        v-show="usuario.imagenPerfilUrl"
                        data-aos="zoom-in"
                        class="img-fluid bg-img-user"
                        alt="imagen_perfil"
                        @click="uploadImage"
                      /> -->


        <button @click="uploadImage()" class="send-button mr-2" v-b-tooltip.top title="click para subir archivo">
                          <img src="/img/icons/subir-archivo.png" width="30" alt="">

          <!-- <i  class="fas fa-spinner fa-spin"></i> -->
        </button>
        <input v-model="mensaje" class="message-input" placeholder="Escribe un mensaje" />
        <button @click="enviarMensaje()" class="send-button">
          Enviar &nbsp;
          <i v-if="enviando" class="fas fa-spinner fa-spin"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  sockets: {
    connect: function () {
      console.log("conectado a cockets. en app.vue***************************************************************");
    },
    nuevo_mensaje: async function (data) {
      let usuario = JSON.parse(localStorage.getItem("setPersonaAct")) || null;
      console.log(data);
      if (usuario != null) {
        if (data.ParaId == usuario.id || (data.ParaId == null && usuario.rol.nivel == 4)) {
          this.getData();
        }
      }
    },
  },
  data() {
    return {
      enviando: false,
      personaId: null,
      usuario: {},
      id: null,
      messages: [
        // { text: "Mensaje enviado", type: "sent" },
        // { text: "Mensaje recibido", type: "received" },
        // Agrega más mensajes aquí con sus respectivos tipos
      ],
      mensaje: "",
      Detalle: { Id: null, TicketId: null, urlAdjunto: null, DeId: null, ParaId: null, Mensaje: null, Fecha: null, Leido: false },
      files: null,
      filelist: [],
      file1: null,
      filename: null,      
    };
  },
  async mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));

    this.id = this.$route.params.id;
    this.personaId = this.$route.params.userid;
    await this.getData();
  },
  methods: {
    uploadImage() {
      this.$refs.fileInput.click();
    },    
    clearVarFiles() {
      this.file1 = null;
      this.filename = null;
      this.files = [];
      this.filelist = [];
    },    
    handleFileUpload(event) {
      this.file = event.target.files[0];
      console.log('handle', this.file)

      //this.uploadImagenPerfilUrl(file);
    },
    async renameFile(nameFile) {
      this.realName = nameFile;
      let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
      let collectionGood = "a e i o u A E I O U n N";
      let collectionBadA = collectionBad.split(" ");
      let collectionGoodA = collectionGood.split(" ");
      let count = 0;
      for await (let it of collectionBadA) {
        let pos = nameFile.indexOf(it);
        if (pos > 0) {
          nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
        }
        pos = 0;
        count++;
      }
      return nameFile;
    },
    async uploadImagenPerfilUrl(ticketId) {
      try {
        console.log('uploadimgfle')
        //this.$isLoading(true);
        this.filename = await this.renameFile(this.file.name); // Math.floor(Math.random() * 9999999999999 + 1);
        console.log('uploadimgfle', this.filename)
        if (this.filename != null) {
          let url = ".|site1|users|tickets|" + ticketId;
          let uploadImg = await this.$store.dispatch("onFileCustom", {
            filename: this.filename,
            file: this.file,
            url: url,
          });
          if (uploadImg == true) {
            return "https://www.helexiumfiles.com/users/tickets/" + ticketId + "/" + this.filename;
          }
        }
        //this.$isLoading(false);
      } catch (error) {
        //this.$isLoading(false);
       // Swal.fire("Error!", "Ha ocurrido un error al guardar la foto.", "error");
        console.log("errror upload", error);
      }
    },    
    scrollFix: function () {
      setTimeout(() => {
        const el = this.$refs.chatmessages;
        el.scrollIntoView({ behavior: "smooth", block: "end" });
        console.log("ok");
      }, 1000);
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaTicket/GetByTicket/" + this.id,
        });
        console.log(res)
        if (res.length > 0) {
          this.messages = res;
          this.scrollFix();
        } else {
          this.messages = [];
        }
      } catch (error) {
        this.messages = [];
        console.log("err", error);
      }
    },
    async enviarMensaje() {
      try {
        this.enviando = true;
        if (this.usuario.rol.nivel == 4) {
          this.Detalle.ParaId = this.personaId;
        }
        this.Detalle.DeId = this.usuario.id;
        this.Detalle.TicketId = this.id;
        this.Detalle.Fecha = this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDTHH:mm");
        this.Detalle.Mensaje = this.mensaje;
        this.$store.e;
        if(this.file!=null){
          this.Detalle.urlAdjunto = await this.uploadImagenPerfilUrl(this.id);
        }
        console.log(this.Detalle)
        let dataMensaje = await this.$store.dispatch("hl_post", {
          path: "PersonaTicket/NuevoTicketDetalle",
          data: this.Detalle,
        });
        /////////////
        //enviar mensaje socket
        let dataMensajeSocket = await this.$store.dispatch("postw", {
          path: "enviarNotificacion",
          data: this.Detalle,
        });
        console.log("socket enviando", dataMensajeSocket);
        ////////////////////////
        let dataCorreo = {};
        if (this.usuario.rol.nivel == 4) {
          dataCorreo = {
            Correo: this.usuario.correo, // "absanchezmena@gmail.com", // enviar correo a usuario
            Asunto: "Nuevo mensaje...",
            Mensaje: this.Detalle.Mensaje,
          };
        } else {
          dataCorreo = {
            Correo: "Info@helexium.cloud", // "absanchezmena@gmail.com", // enviar correo a admin helex
            Asunto: "Nuevo mensaje...",
            Mensaje: this.Detalle.Mensaje,
          };
        }
        await this.$store.dispatch("hl_post", {
          path: "Persona/EnvioNotificacionCorreoCustom",
          data: dataCorreo,
        });
        //////////////////////
        console.log(dataMensaje);
        this.mensaje = "";
        this.enviando = false;
        this.getData();
        this.$store.commit("refrescarChat", 0);
        this.clearVarFiles()
        this.scrollFix();
      } catch (error) {
        this.enviando = false;
      }
    },
  },
};
</script>

<style scoped>
.chat-app {
  display: flex;
  max-width: 80%;
  height: 70vh;
  margin: 0 auto;
  background-color: #afafaf;
}

.chat-list {
  width: 20%;
  padding: 20px;
  border-radius: 5%;
  background-color: #e9e9e9;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.chat-item {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #292929;
  color: #1b1b1b;
}

.chat {
  background-image: url("/img/chat-2.png");
  background-repeat: repeat;
  flex: 1;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  color: #222222;
}

.chat-header {
  background-color: #eeeeee;
  color: #363636;
  padding: 10px;
  font-weight: bold;
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  max-width: 70%;
}

.sent-message {
  background-color: rgb(245, 245, 245);
  color: #030303;
  align-self: flex-end;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.096);
  border-radius: 10px;
  padding: 12px;
}

.received-message {
  background-color: #c4c4c4;
  color: #0a0a0a;
  align-self: flex-start;
  max-width: 70%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 12px;
}

.message-input-container {
  display: flex;
  padding: 10px;
  background-color: #ffffff;
}

.message-input {
  flex: 1;
  padding: 10px;
  color: rgb(3, 3, 3);
  border-radius: 5%;
  background-color: #dddddd;
  border: 1px solid #ced1d6;
  border-radius: 5px;
}

.send-button {
  padding: 10px 20px;
  background-color: #186bdd;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}





/* DARK */

/* .dark .message-input-container {
  display: flex;
  padding: 10px;
  background-color: #1e2023;
}

.dark .message-input {
  flex: 1;
  padding: 10px;
  color: #dfdfdf;
  border-radius: 5%;
  background-color: #1e2023;
  border: 1px solid #1e2023;
  border-radius: 5px;
} */

.dark .chat-app {
  display: flex;
  max-width: 80%;
  height: 70vh;
  margin: 0 auto;
  background-color: #18191c;
}

.dark .chat-list {
  width: 20%;
  padding: 20px;
  border-radius: 5%;
  background-color: #1e2023;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.dark .chat-item {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  color: #ffffff;
}

.dark .chat {
  background-image: url("/img/chat.png");
  background-repeat: repeat;
  flex: 1;
  background-color: #131416;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}

.dark .chat-header {
  background-color: #131416;
  color: #ffffff;
  padding: 10px;
  font-weight: bold;
}

.dark .chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.dark .message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  max-width: 70%;
}

.dark .sent-message {
  background-color: #1e2023;
  color: #ffffff;
  align-self: flex-end;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 12px;
}

.dark .received-message {
  background-color: #17191b;
  color: #f1f1f1;
  align-self: flex-start;
  max-width: 70%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 12px;
}

.dark .message-input-container {
  display: flex;
  padding: 10px;
  background-color: #131416;
}

.dark .message-input {
  flex: 1;
  padding: 10px;
  color: white;
  border-radius: 5%;
  background-color: #1e2023;
  border: 1px solid #1e2023;
  border-radius: 5px;
}

.dark .send-button {
  padding: 10px 20px;
  background-color: #0073e6;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}
</style>
